<script setup lang="ts"></script>

<template>
	<div>
		The Test component
	</div>
</template>

<style scoped>
div {
	background-color: black;
	color: white;
}
</style>